<template>
	<div class="page-layout">
		<gc-header headerData="regionManage"></gc-header>
		<div class="content" v-loading="loading">
			<div class="address-tree" v-if="treeOptions.length > 0">
				<div class="search-modal">
					<gc-custom-search
						keyWord="区域名称"
						width="500px"
						:search.sync="searchForm.areaName"
					></gc-custom-search>
					<div class="serach-ops">
						<el-button type="primary" v-click-blur @click="handleSearch">查 询</el-button>
						<el-button v-click-blur @click="handleReset">重 置</el-button>
					</div>
				</div>
				<el-tree
					ref="regionTree"
					node-key="id"
					:data="treeOptions"
					default-expand-all
					highlight-current
					:expand-on-click-node="false"
					icon-class="el-icon-arrow-right"
					:props="{
						label: 'name',
						children: 'children',
					}"
					@node-click="data => (currentRegionInfo = data)"
					:filter-node-method="filterNode"
				>
					<div class="custom-tree-node" slot-scope="{ node }">
						<p>{{ node.label }}</p>
						<div
							class="operate-group"
							v-if="
								$has('monitor:waterregion:manage:insert') ||
								$has('monitor:waterregion:manage:update') ||
								$has('monitor:waterregion:manage:delete')
							"
						>
							<span
								v-if="$has('monitor:waterregion:manage:insert')"
								class="icon"
								@click.stop="
									parentId = node.data.id
									editRegionInfo = null
									modalVisible = true
								"
							>
								<i class="el-icon-plus"></i>
							</span>
							<span
								class="icon"
								v-if="$has('monitor:waterregion:manage:update')"
								@click.stop="handleEditAddress(node.data)"
							>
								<i class="el-icon-edit"></i>
							</span>
							<span
								class="icon"
								v-if="$has('monitor:waterregion:manage:delete')"
								@click.stop="handleDeleteAddress(node.data)"
							>
								<i class="el-icon-delete"></i>
							</span>
						</div>
					</div>
				</el-tree>
			</div>
			<div class="basic-address" v-if="treeOptions.length > 0 && currentRegionInfo">
				<div class="icon-title">
					<img class="logo" src="@/assets/imgs/icon/basic.svg" alt="" />
					<p class="title">基础信息</p>
				</div>
				<div class="value-list">
					<div class="value-list-item">
						<div class="t">操作人员</div>
						<div class="v">{{ currentRegionInfo.staffName || '--' }}</div>
					</div>
					<div class="value-list-item">
						<div class="t">操作时间</div>
						<div class="v">{{ currentRegionInfo.updateTime || '--' }}</div>
					</div>
				</div>
			</div>
			<gc-empty
				v-if="treeOptions.length === 0 && $has('monitor:waterregion:manage:init')"
				pageType="regionManage"
				@on-btn-click="handleInitRegion"
			></gc-empty>
			<gc-empty
				v-if="treeOptions.length === 0 && !$has('monitor:waterregion:manage:init')"
				pageType="regionManage2"
			></gc-empty>
		</div>
		<OperateRegion
			v-if="modalVisible"
			@close-modal="handleCloseModal"
			:regionInfo="editRegionInfo"
			:parentId="parentId"
		/>
	</div>
</template>

<script>
import { apiInitWaterRegion, apiDeleteWaterRegion } from '@/apis/regionManage.api'
import OperateRegion from './components/OperateRegion'
import { mapActions } from 'vuex'
export default {
	name: 'AddressManage',
	components: { OperateRegion },
	data() {
		return {
			loading: false,
			treeOptions: [],
			searchForm: {
				areaName: null,
			},
			modalVisible: false,
			currentRegionInfo: null,
			editRegionInfo: null,
			parentId: null,
		}
	},

	created() {
		this.findWaterRegionTreeList()
	},

	methods: {
		...mapActions({
			getTreeOptions: 'apiCache/getTreeOptions',
		}),
		async findWaterRegionTreeList() {
			this.loading = true
			this.searchForm.areaName = null
			try {
				const data = await this.getTreeOptions(true)
				this.treeOptions = data
				this.currentRegionInfo = this.currentRegionInfo || (data.length ? { ...data[0], children: null } : null)
				this.$nextTick(() => {
					this.$refs.regionTree.setCurrentKey(this.currentRegionInfo?.id)
				})
			} catch (e) {
				this.currentRegionInfo = null
			} finally {
				this.loading = false
			}
		},

		handleEditAddress(item) {
			this.parentId = null
			this.editRegionInfo = item
			this.modalVisible = true
		},
		handleDeleteAddress({ id }) {
			this.$confirm('确定删除该区域？删除后将不可撤销。')
				.then(async () => {
					await apiDeleteWaterRegion({ id })
					this.$message.success('删除区域成功')
					this.currentRegionInfo = null
					this.findWaterRegionTreeList()
				})
				.catch(() => {})
		},

		async handleInitRegion() {
			await apiInitWaterRegion()
			this.$message.success('初始化根节点成功')
		},

		handleCloseModal(render) {
			this.modalVisible = false
			if (render) {
				this.findWaterRegionTreeList()
			}
		},

		handleSearch() {
			this.$refs.regionTree.filter(this.searchForm.areaName)
		},

		handleReset() {
			this.searchForm.areaName = null
			this.$refs.regionTree.filter(this.searchForm.areaName)
		},

		filterNode(value, data) {
			if (!value) return true
			return data.name.indexOf(value) !== -1
		},
	},
}
</script>
<style lang="scss" scoped>
.page-layout {
	height: 100%;
	display: flex;
	flex-direction: column;
	.content {
		flex: 1;
		height: 0;
		padding: 0 18px;
		display: flex;
		.address-tree {
			display: flex;
			flex-direction: column;
			flex: 1;
			padding: 24px 24px 24px 12px;
			.search-modal {
				display: flex;
				margin-bottom: 12px;
				padding-right: 10px;
				.serach-ops {
					margin-left: auto;
				}
			}
			::v-deep .el-tree {
				height: 0;
				flex: 1;
				overflow-y: scroll;
				.el-tree-node {
					&__content {
						height: 42px;
						border-radius: 4px;
						.el-icon-arrow-right:not(.is-leaf) {
							color: #3f435e;
							font-size: 11px;
							font-weight: bold;
						}
						.custom-tree-node {
							display: flex;
							justify-content: space-between;
							flex: 1;
							width: 0;
							padding-right: 24px;
							align-items: center;
							color: #3f435e;
							.operate-group {
								display: none;
								.icon {
									border: 1px solid #b4c8fd;
									border-radius: 4px;
									padding: 4px 6px;
									background-color: #fff;
									color: #3f435e;
									cursor: pointer;
									margin-left: 8px;
								}
							}
						}
						&:hover {
							background-color: #f4f5fb;
							.custom-tree-node {
								.operate-group {
									display: block;
								}
							}
						}
					}
					&.is-current {
						& > .el-tree-node__content {
							.custom-tree-node {
								color: #4d6bff;
								font-weight: 600;
							}
						}
					}
				}
			}
		}
		.basic-address {
			width: 400px;
			padding: 26px 12px 24px 24px;
			border-left: 1px solid #f1f1f1;
			.value-list {
				&-item {
					& + .value-list-item {
						margin-top: 62px;
					}
					.t {
						color: #666;
					}
					.v {
						color: #333;
						margin-top: 12px;
					}
				}
			}
		}
		.icon-title {
			display: flex;
			align-items: center;
			margin-bottom: 20px;
			.title {
				font-size: 16px;
				color: #333333;
				font-weight: 500;
				margin-left: 8px;
			}
		}
		::v-deep .gc-blank-page {
			width: 100%;
			height: 100%;
		}
	}
}
</style>
